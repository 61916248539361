import React, { Component } from 'react'
import styled from 'styled-components'
import { Button } from '../button'
import { colors } from '../../styles/colors'
import { Consumer } from '../../store/createContext'
import qs from 'qs'

const PreHeading = styled.p`
  color: ${colors.quaternary.default};
  margin-top: 0;
`

const Success = styled.p`
  color: ${colors.quaternary.default};
  margin-top: 40px;

  @media (min-width: 992px) {
    max-width: 75%;
  }
`

const Input = styled.span`
  display: inline-block;
  background-color: ${props => (props.dark ? colors.primary.l : '#F2F4F5')};
  position: relative;
  z-index: 1;
  font-size: 16px;
  color: ${props => (props.theme === 'dark' ? '#FFF' : '#002D3E')};
  letter-spacing: 0;
  line-height: 24px;
  padding: 6px;
  margin-bottom: 10px;

  &.error {
    background-color: ${props =>
      props.dark ? 'rgba(239,70,59,0.5)' : 'rgba(239,70,59,0.1)'};

    &:after {
      background-color: ${colors.quaternary.default};
    }
  }

  input {
    font-family: 'FoundersGroteskMonoTest-Regular';
    color: ${props => (props.theme === 'dark' ? '#FFF' : '#002D3E')};
    background-color: transparent;
    border: none;
    -webkit-appearance: none;
    font-size: 16px;
    padding: 0;

    &::placeholder {
      color: ${props => (props.dark ? '#FFF' : colors.primary.default)};
    }

    &:active,
    &:focus {
      outline: none;
    }
  }

  &:hover {
    cursor: pointer;

    :after {
      transition: width 0.25s linear;
    }
  }

  &.active:after {
    background-color: ${colors.quaternary.default};
    transition: background-color 0.25s ease-in-out;
  }

  &:after {
    width: ${props => (props.width ? props.width - 10 + 'px' : '80%')};
    content: '';
    display: block;
    position: absolute;
    bottom: 11px;
    left: 0;
    height: 8px;
    background-color: ${props => (props.dark ? colors.grey.d : '#BFBFBF')};
    z-index: -1;
    transition: background-color 0.25s ease-in-out;
  }
`

const Submit = styled.div`
  text-align: right;

  button {
    background: transparent;
    padding: 0;
    border: none;
  }
`

export class ContactForm extends Component {
  constructor() {
    super()
    this.Name = null
    this.Organization = null
    this.Goals = null
    this.Budget = null
    this.Timeframe = null
    this.Contact = null
  }

  state = {
    activeInput: null,
    name: '',
    organization: '',
    goals: '',
    budget: '',
    timeframe: '',
    contact: '',
    botField: '',
    formStatus: '',
    inputWidth: {
      name: null,
      organization: null,
      goals: null,
      budget: null,
      timeframe: null,
      contact: null,
    },
    formErrors: {
      name: false,
      organization: false,
      goals: false,
      budget: false,
      timeframe: false,
      contact: false,
    },
  }

  componentDidMount = () => {
    this.setState({
      inputWidth: {
        name: this.Name.placeholder.length * 10,
        organization: this.Organization.placeholder.length * 10,
        goals: this.Goals.placeholder.length * 10,
        budget: this.Budget.placeholder.length * 10,
        timeframe: this.Timeframe.placeholder.length * 10,
        contact: this.Contact.placeholder.length * 10,
      },
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    if (this.state.botField !== '') {
      this.setState({ formStatus: 'success' })
    } else {
      if (
        this.state.name.length < 2 ||
        this.state.organization.length < 2 ||
        this.state.goals.length < 2 ||
        this.state.budget.length < 2 ||
        this.state.timeframe.length < 2 ||
        this.state.contact.length < 2
      ) {
        this.setState({
          formErrors: {
            name: this.state.name.length < 2,
            organization: this.state.organization.length < 2,
            goals: this.state.goals.length < 2,
            budget: this.state.budget.length < 2,
            timeframe: this.state.timeframe.length < 2,
            contact: this.state.contact.length < 2,
          },
        })
      } else {
        this.submitForm()
      }
    }
  }

  submitForm = () => {
    fetch('https://formspree.io/f/xvovnqpy', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'Accept': 'application/json'
      },
      body: qs.stringify({
        // 'form-name': 'contact',
        // 'bot-field': this.state.botField,
        name: this.state.name,
        organization: this.state.organization,
        goals: this.state.goals,
        budget: this.state.budget,
        timeframe: this.state.timeframe,
        contact: this.state.contact,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          this.setState({ formStatus: 'success' })
        }
      })
      .catch(error => console.log(error))
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      formErrors: {
        ...this.state.formErrors,
        [e.target.name]: e.target.value.length < 2,
      },
    })
  }

  render() {
    return (
      <Consumer>
        {({ dark }) => (
          <div>
            <PreHeading className="blockquote">Contact Us</PreHeading>
            <h4>Fill this out and we'll be in touch soon!</h4>
            <form
              name="contact"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={this.handleSubmit}
            >
              <div style={{ display: 'none' }}>
                <input name="bot-field" onChange={this.handleChange} />
              </div>
              <p>
                Hey, my name is&ensp;
                <Input
                  className={`${
                    this.state.activeInput === 'name' ? 'active' : ''
                  } ${this.state.formErrors.name && 'error'}`}
                  width={this.state.inputWidth.name}
                  dark={dark}
                >
                  <input
                    name={'name'}
                    onFocus={() => this.setState({ activeInput: 'name' })}
                    onBlur={() => this.setState({ activeInput: null })}
                    ref={input => (this.Name = input)}
                    style={{
                      width: this.state.inputWidth.name
                        ? this.state.inputWidth.name
                        : 'unset',
                    }}
                    value={this.state.name}
                    onChange={this.handleChange}
                    placeholder={'full name'}
                  />
                </Input>
                &ensp; and I'm with&ensp;
                <Input
                  className={`${
                    this.state.activeInput === 'organization' ? 'active' : ''
                  } ${this.state.formErrors.organization && 'error'}`}
                  width={this.state.inputWidth.organization}
                  dark={dark}
                >
                  <input
                    name={'organization'}
                    onFocus={() =>
                      this.setState({ activeInput: 'organization' })
                    }
                    onBlur={() => this.setState({ activeInput: null })}
                    ref={input => (this.Organization = input)}
                    style={{
                      width: this.state.inputWidth.organization
                        ? this.state.inputWidth.organization
                        : 'unset',
                    }}
                    placeholder={'my organization'}
                    value={this.state.organization}
                    onChange={this.handleChange}
                  />
                </Input>
                &ensp; . I need a partner to help me with&ensp;
                <Input
                  className={`${
                    this.state.activeInput === 'goals' ? 'active' : ''
                  } ${this.state.formErrors.goals && 'error'}`}
                  width={this.state.inputWidth.goals}
                  dark={dark}
                >
                  <input
                    name={'goals'}
                    onFocus={() => this.setState({ activeInput: 'goals' })}
                    onBlur={() => this.setState({ activeInput: null })}
                    ref={input => (this.Goals = input)}
                    style={{
                      width: this.state.inputWidth.goals
                        ? this.state.inputWidth.goals
                        : 'unset',
                    }}
                    placeholder={'my goals in a snapshot'}
                    value={this.state.goals}
                    onChange={this.handleChange}
                  />
                </Input>
                &ensp; . I'm looking to stay around a budget of&ensp;
                <Input
                  className={`${
                    this.state.activeInput === 'budget' ? 'active' : ''
                  } ${this.state.formErrors.budget && 'error'}`}
                  width={this.state.inputWidth.budget}
                  dark={dark}
                >
                  <input
                    name={'budget'}
                    onFocus={() => this.setState({ activeInput: 'budget' })}
                    onBlur={() => this.setState({ activeInput: null })}
                    ref={input => (this.Budget = input)}
                    style={{
                      width: this.state.inputWidth.budget
                        ? this.state.inputWidth.budget
                        : 'unset',
                    }}
                    placeholder={'budget'}
                    value={this.state.budget}
                    onChange={this.handleChange}
                  />
                </Input>
                &ensp; and have it completed on or near&ensp;
                <Input
                  className={`${
                    this.state.activeInput === 'timeframe' ? 'active' : ''
                  } ${this.state.formErrors.timeframe && 'error'}`}
                  width={this.state.inputWidth.timeframe}
                  dark={dark}
                >
                  <input
                    name={'timeframe'}
                    onFocus={() => this.setState({ activeInput: 'timeframe' })}
                    onBlur={() => this.setState({ activeInput: null })}
                    ref={input => (this.Timeframe = input)}
                    style={{
                      width: this.state.inputWidth.timeframe
                        ? this.state.inputWidth.timeframe
                        : 'unset',
                    }}
                    placeholder={'date/timeframe'}
                    value={this.state.timeframe}
                    onChange={this.handleChange}
                  />
                </Input>
                &ensp; . You can reach me at&ensp;
                <Input
                  className={`${
                    this.state.activeInput === 'contact' ? 'active' : ''
                  } ${this.state.formErrors.contact && 'error'}`}
                  width={this.state.inputWidth.contact}
                  dark={dark}
                >
                  <input
                    name={'contact'}
                    onFocus={() => this.setState({ activeInput: 'contact' })}
                    onBlur={() => this.setState({ activeInput: null })}
                    ref={input => (this.Contact = input)}
                    style={{
                      width: this.state.inputWidth.contact
                        ? this.state.inputWidth.contact
                        : 'unset',
                    }}
                    placeholder={'email/phone'}
                    value={this.state.contact}
                    onChange={this.handleChange}
                  />
                </Input>
                &ensp; to get the conversation started. Thanks!
              </p>
              {this.state.formStatus !== 'success' ? (
                <Submit>
                  <button>
                    <Button>Let's Do This</Button>
                  </button>
                </Submit>
              ) : (
                <Success className="blockquote">
                  Your information was submitted successfully, we'll be in touch
                  soon.
                </Success>
              )}
            </form>
          </div>
        )}
      </Consumer>
    )
  }
}
